<template>
  <v-card
    v-if="
      $power.check($route.meta.power) && USER.userInfo.departments.length > 0
    "
    :dark="$dark.get()"
    min-height="90vh"
  >
    <v-card-title class="card-title">
      <div class="header">
        <h1 class="header_title">Управление заказами</h1>
        <!-- поиск заказов -->
        <v-text-field
          v-model="searchingOrderId"
          label="Поиск заказов по ID..."
          class="header_input"
          hide-details
          solo
          type="number"
          @keyup.enter="findOrder()"
          @input="clearInput()"
        >
          <v-btn
            v-if="searchingOrderId"
            slot="append"
            color="red"
            icon
            @click="clearInput(true)"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-btn slot="append" color="grey" icon @click="findOrder()">
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </v-text-field>
      </div>
      <!-- блок статистики -->
      <div class="stat">
        <div class="stat_block">
          <p class="stat_text color-blue">
            Заказы <span>{{ stat.count }}</span>
          </p>
          <p class="stat_text">
            Новые <span>{{ stat.newOrdersCount }}</span>
          </p>
          <p class="stat_text">
            В обработке <span>{{ stat.processingOrdersCount }}</span>
          </p>
          <p class="stat_text">
            Отменены <span>{{ stat.cancelledOrdersCount || 0 }}</span>
          </p>
        </div>
        <div class="stat_block">
          <p class="stat_text color-orange">
            Средний чек <span>{{ stat.avg }}</span>
          </p>
          <p class="stat_text">
            Средний чек <br />
            отмененных <span>{{ stat.avgCancelledCheck }}</span>
          </p>
        </div>
        <div class="stat_block">
          <p class="stat_text color-green">
            Оборот <span>{{ stat.sum }}</span>
          </p>
          <p class="stat_text">
            Недополученный <br />
            оборот <span>{{ stat.cancelledOrderSum }}</span>
          </p>
        </div>
      </div>
      <!-- блок фильтров -->
      <!-- <div class="filters">
        <p class="stat_text">Быстрые фильтры</p>
        <div class="filters_block">
          <v-btn color="#D9D9D9" class="filters_btn">Новый</v-btn>
          <v-btn color="#D9D9D9" class="filters_btn">В обработке</v-btn>
          <v-btn color="#D9D9D9" class="filters_btn">Готовы к отгрузке</v-btn>
        </div>
        <div class="filters_block">
          <v-btn color="error" class="filters_btn"
            >В обработке более 15 мин</v-btn
          >
          <v-btn color="error" class="filters_btn">Готовы более 15 мин</v-btn>
        </div>
      </div> -->
      <!-- периоды отображения -->
      <div class="periods">
        <p class="stat_text">Периоды отображения заказов</p>
        <div class="periods_block">
          <div class="periods_btn">
            <v-btn class="filters_btn" @click="setFilter(2)">Вчера</v-btn>
            <v-btn
              color="#89CE38"
              dark
              class="filters_btn"
              @click="setFilter(1)"
              >Сегодня</v-btn
            >
          </div>
          <div class="periods_time">
            <p class="periods_text">Задать период</p>
            <v-row>
              <v-col cols="6" class="pa-0">
                <v-menu
                  ref="menu"
                  v-model="menu1"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  :dark="$dark.get()"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="input.startTime"
                      readonly
                      outlined
                      v-bind="attrs"
                      hide-details
                      dense
                      class="periods_input"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="input.startTime"
                    no-title
                    :max="input.endTime"
                    @change="
                      setOrder();
                      menu1 = !menu1;
                    "
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6" class="pa-0">
                <v-menu
                  ref="menu"
                  v-model="menu2"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  :dark="$dark.get()"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      class="periods_input"
                      :value="input.endTime"
                      readonly
                      outlined
                      v-bind="attrs"
                      hide-details
                      dense
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="input.endTime"
                    no-title
                    :min="input.startTime"
                    @change="
                      setOrder();
                      menu2 = !menu2;
                    "
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
      <!-- сортировку пока убрали -->
      <!-- <div style="width: 200px" class="mr-3">
          <v-select
            v-model="input.sorted"
            outlined
            hide-details
            dense
            :items="sortList"
            item-text="name"
            item-value="id"
            label="Сортировка"
          ></v-select>
        </div> -->
    </v-card-title>
    <!-- блок нижних кнопок -->
    <div class="bottom-btns">
      <v-btn color="grey" to="order/create" icon x-large>
        <v-icon>mdi-plus-circle</v-icon>
      </v-btn>
      <v-btn
        class="filters_btn white--text"
        color="grey"
        @click="preOrderState = !preOrderState"
        >{{
          preOrderState ? "Показать все заказы" : "Показать предзаказы"
        }}</v-btn
      >
    </div>
    <loader v-if="loading"></loader>
    <order-table
      v-if="!loading && !OrderTableMode"
      :items="orders"
      :checks="checks"
      :statuses="STATUS.allStatus"
      :payments="PAYMENT.allPayment"
      :departments="DEPARTMENT.cityDepartment"
      :picker="pickerDep"
      :courier="courierDep"
      :last="stat.count"
      :input="input"
      @delete-button="deleteOrders"
      @change-status="changeStatus"
      @change-payment="changePayment"
      @change-department="changeDepartment"
      @change-picker="changePicker"
      @change-courier="changeCourier"
      @change-delivery="setOrder"
      @change_pay_status="changePaymentStatus"
      @set-input="
        input.page = 0;
        setOrder();
      "
      @load-more="loadMore"
      @render-orders="setAll"
    ></order-table>
    <mobile-order-table
      v-if="!loading && OrderTableMode"
      :items="orders"
      :checks="checks"
      :statuses="STATUS.allStatus"
      :payments="PAYMENT.allPayment"
      :departments="DEPARTMENT.cityDepartment"
      :picker="pickerDep"
      :courier="courierDep"
      :last="stat.count"
      :input="input"
      @delete-button="deleteOrders"
      @change-status="changeStatus"
      @change_pay_status="changePaymentStatus"
      @change-payment="changePayment"
      @change-department="changeDepartment"
      @change-picker="changePicker"
      @change-courier="changeCourier"
      @change-delivery="setOrder"
      @set-input="
        input.page = 0;
        setOrder();
      "
      @load-more="loadMore"
      @render-orders="setAll"
    ></mobile-order-table>
    <v-overlay :value="overlayLoading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <p v-if="orders.length === 0" class="text-center">Заказов нет</p>
  </v-card>
</template>
<script>
import order from "@/model/order";
import { mapActions, mapGetters } from "vuex";
import OrderTable from "@/components/Order/OrderTable";
import Loader from "@/components/Order/Loader";
import moment from "moment";
import showMessage from "@/Functions/message";
import MobileOrderTable from "@/components/Order/mobileOrderTable";
import websocket from "@/Functions/sockets";

export default {
  components: { Loader, OrderTable, MobileOrderTable },
  data() {
    return {
      overlayLoading: false,
      input: {},
      orders: [],
      loading: true,
      checks: [],
      picker: [],
      courier: [],
      page: 1,
      show: false,
      stat: {},
      filterTypes: [
        { id: 1, name: "Сегодня" },
        { id: 2, name: "Вчера" },
        { id: 3, name: "Период" },
      ],
      sortList: [
        { id: "createdAt", name: "Дата доставки" },
        { id: "id", name: "ID" },
      ],
      filter: 1,
      menu1: false,
      menu2: false,
      preOrderState: false,
      searchingOrderId: null,
    };
  },
  computed: {
    ...mapGetters({
      ORDER: "Order/STATE",
      STATUS: "Status/STATE",
      PAYMENT: "Payment/STATE",
      DEPARTMENT: "Department/STATE",
      USER: "User/STATE",
    }),
    deleteChecks() {
      return this.checks.filter((val) => val.state === true);
    },
    pickerDep() {
      return this.picker.filter((x) => {
        if (this.input.departmentIds.length !== 0) {
          if (this.input.departmentIds[0] === x.departmentId) {
            return x;
          }
        } else {
          return x;
        }
      });
    },
    courierDep() {
      return this.courier.filter((x) => {
        if (this.input.departmentIds.length !== 0) {
          if (this.input.departmentIds[0] === x.departmentId) {
            return x;
          }
        } else {
          return x;
        }
      });
    },
    OrderTableMode() {
      if (
        this.$vuetify.breakpoint.name === "lg" ||
        this.$vuetify.breakpoint.name === "xl"
      ) {
        return false;
      } else return true;
    },
  },
  watch: {
    async preOrderState(val) {
      if (val) {
        this.input.onlyPreOrders = true;
        this.input.page = 0;
        await this.preOrderAll(this.input);
        await this.setStat(this.input);
        this.orders = this.ORDER.allPreOrder.content;
      } else {
        this.input.onlyPreOrders = false;
        await this.setStat(this.input);
        this.orders = this.ORDER.allOrder.content;
      }
    },
    orders: {
      handler() {
        if (this.orders !== null) {
          this.checks = this.orders.map(function (x) {
            return {
              state: false,
              id: x.orderId,
            };
          });
        }
      },
      deep: true,
    },
  },
  async created() {
    await this.setAll();
    websocket(this);
  },
  methods: {
    ...mapActions({
      orderAll: "Order/GET_ALL_ORDER",
      moreOrder: "Order/GET_MORE_ORDER",
      preOrderAll: "Order/GET_ALL_PRE_ORDER",
      deleteOrder: "Order/DELETE_ORDER",
      allStatus: "Status/GET_ALL_STATUS",
      allPayStatus: "Status/GET_ALL_PAY_STATUS",
      getPayment: "Payment/GET_ALL_PAYMENT",
      getCourier: "Employee/GET_COURIER",
      getPicker: "Employee/GET_PICKER",
      EDIT_STATUS: "Order/EDIT_STATUS",
      EDIT_STATUS_WITH_TAG: "Order/EDIT_STATUS_WITH_TAG",
      editPaymentStatus: "Order/EDIT_PAYMENT_STATUS",
      editPayment: "Order/EDIT_PAYMENT",
      editDepartment: "Order/EDIT_DEPARTMENT",
      editCourier: "Order/EDIT_COURIER",
      editPicker: "Order/EDIT_PICKER",
      getStatistic: "Order/GET_STATISTIC",
      GET_CANCEL_TAGS: "Status/GET_CANCEL_TAGS",
      FIND_ORDER: "Order/FIND_ORDER",
    }),
    async setAll() {
      let model = new order();
      let promisArr = [];
      if (!this.STATUS.cancelTags.length > 0) {
        promisArr.push(this.GET_CANCEL_TAGS());
      }
      promisArr.push(this.allStatus());
      promisArr.push(this.allPayStatus());
      promisArr.push(this.getPayment());
      await Promise.allSettled(promisArr);
      this.picker = await this.getPicker(this.$root.city);
      this.courier = await this.getCourier(this.$root.city);
      this.input = model.setInput(this.$root.city);
      if (this.USER.userInfo.departments.length === 1) {
        this.input.departmentIds = [this.USER.userInfo.departments[0].id];
      }
      await this.setOrder();
    },
    async setOrder() {
      this.loading = true;
      await this.orderAll(this.input);
      await this.setStat();
      this.orders = this.ORDER.allOrder.content;
      this.loading = false;
    },
    async loadMore() {
      this.overlayLoading = true;
      this.input.page++;
      await this.moreOrder(this.input);
      await this.setStat();
      this.orders = this.ORDER.allOrder.content;
      this.overlayLoading = false;
    },
    async setStat() {
      this.stat = await this.getStatistic(this.input);
    },
    async setFilter(val) {
      this.loading = true;
      // для сегодня
      if (val === 1) {
        this.input.page = 0;
        this.input.startTime = moment().format("YYYY-MM-DD");
        this.input.endTime = moment().format("YYYY-MM-DD");
        await this.setOrder();
      } else if (val === 2) {
        // для вчера
        this.input.page = 0;
        this.input.startTime = moment().add(-1, "d").format("YYYY-MM-DD");
        this.input.endTime = moment().add(-1, "d").format("YYYY-MM-DD");
        await this.setOrder();
      }
      this.loading = false;
    },
    async deleteOrders() {
      this.loading = true;
      let errors = [];
      let response = null;
      for (const order of this.deleteChecks) {
        response = await this.deleteOrder(order.id);
        if (response?.type === "error") {
          errors.push(order.orderId);
        }
      }
      if (errors.length > 0) {
        showMessage(response.data.message);
      }
      this.loading = false;
    },
    async changeStatus(item) {
      this.loading = true;
      const input = {
        statusId: item.status,
        orderId: item.order,
        tagId: item.tag,
      };
      let response;
      if (input.statusId === 9) {
        response = await this.EDIT_STATUS_WITH_TAG(input);
      } else {
        response = await this.EDIT_STATUS(input);
      }
      if (response.type === "error") {
        showMessage(response.data.message);
      } else {
        showMessage(`Статус изменен`, true);
      }
      this.loading = false;
    },
    async changePaymentStatus(payload) {
      this.loading = true;
      let response = await this.editPaymentStatus(payload);
      if (response?.type === "error") {
        showMessage(response.data.message);
      } else {
        showMessage(
          `Статус оплаты для заказа ${payload.orderId} изменен`,
          true
        );
      }
      this.loading = false;
    },
    async changePayment(item) {
      this.loading = true;
      const input = {
        paymentTypeId: item.payment,
        orderId: item.order,
      };
      let response = await this.editPayment(input);
      if (response.type === "error") {
        showMessage(response.text);
      }
      this.loading = false;
    },
    async changeDepartment(item) {
      this.loading = true;
      const input = {
        departmentId: item.department,
        orderId: item.order,
      };
      let response = await this.editDepartment(input);
      if (response?.type === "error") {
        showMessage(response.data.message);
      } else {
        showMessage("Филиал для заказа изменен", true);
      }
      this.loading = false;
    },
    async changeCourier(item) {
      this.loading = true;
      const input = {
        courierId: item.courier,
        orderId: item.order,
      };
      let response = await this.editCourier(input);
      if (response.type === "error") {
        showMessage(response.data.message);
      } else {
        showMessage("Курьер изменен", true);
      }
      this.loading = false;
    },
    async changePicker(item) {
      this.loading = true;
      const input = {
        pickerId: item.picker,
        orderId: item.order,
      };
      let response = await this.editPicker(input);
      if (response.type === "error") {
        showMessage(response.data.message);
      } else {
        showMessage("Комплектовщик изменен", true);
      }
      this.loading = false;
    },
    async findOrder() {
      if (!this.searchingOrderId) {
        return;
      }
      await this.FIND_ORDER(this.searchingOrderId);
      if (this.ORDER.searchingOrder) {
        this.orders = [this.ORDER.searchingOrder];
      }
    },
    clearInput(buttonClick) {
      if (buttonClick) {
        this.orders = this.ORDER.allOrder.content;
        this.searchingOrderId = null;
        return;
      }
      if (!this.searchingOrderId) {
        this.orders = this.ORDER.allOrder.content;
      }
    },
  },
};
</script>
<style lang="scss">
@import "./style";
</style>
