var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('delete-dialog',{attrs:{"show":_vm.deleteDialog},on:{"close-modal":function($event){return _vm.closeModal()},"delete-modal":function($event){return _vm.closeModal(true)}}}),_c('delete-dialog',{attrs:{"show":_vm.cancelOrderDialog,"delete-button-text":'Отменить Заказ'},on:{"close-modal":function($event){return _vm.closeCancelModal()},"delete-modal":function($event){return _vm.closeCancelModal(true)}},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('v-select',{staticStyle:{"max-width":"90%","margin":"0 auto"},attrs:{"label":"Причина","items":_vm.STATUS.cancelTags,"item-text":"name","item-value":"id"},model:{value:(_vm.selectedCancelTag),callback:function ($$v) {_vm.selectedCancelTag=$$v},expression:"selectedCancelTag"}})]},proxy:true}])}),_c('ReturnOrder',{attrs:{"show":_vm.returnDialog,"order":_vm.selectedOrder},on:{"close-modal":function($event){return _vm.closeReturnDialog($event)}}}),_c('v-simple-table',{staticClass:"orders_wrapper",attrs:{"fixed-header":"","width":"100%"}},[_c('thead',[_c('tr',{staticClass:"orders_header"},[(
            _vm.$power.check('admin_order_edit') ||
            _vm.$power.check('admin_order_delete')
          )?_c('th',{staticClass:"orders_head"}):_vm._e(),_c('th',{staticClass:"orders_head"},[_vm._v("№")]),_c('th',{staticClass:"orders_head"},[_vm._v("ID")]),_c('th',{staticClass:"orders_head"},[_vm._v("Создан")]),_c('th',{staticClass:"orders_head"},[_vm._v("Доставлен")]),_c('th',{staticClass:"orders_head"},[_vm._v("Клиент")]),_c('th',{staticClass:"orders_head"},[_vm._v("Адрес")]),_c('th',{staticClass:"orders_head"},[_vm._v("Стоимость")]),_c('th',{staticClass:"orders_head",staticStyle:{"max-width":"130px"}},[_c('AutoComplete',{attrs:{"input":_vm.input,"items":_vm.departments,"name":"Филиал","items-name":"name","input-key":"departmentIds"},on:{"change":function($event){return _vm.$emit('set-input')}}})],1),_c('th',{staticClass:"orders_head"},[_vm._v("Тип оплаты")]),_c('th',{staticClass:"orders_head text-left pa-2 pt-0 pb-0"},[_vm._v("Статус оплаты")]),_c('th',{staticClass:"orders_head",staticStyle:{"max-width":"130px"}},[_c('AutoComplete',{attrs:{"input":_vm.input,"items":_vm.statuses,"name":"Статус","items-name":"name","input-key":"statuses"},on:{"change":function($event){return _vm.$emit('set-input')}}})],1),_c('th',{staticClass:"orders_head",staticStyle:{"max-width":"130px"}},[_c('AutoComplete',{attrs:{"input":_vm.input,"items":_vm.courier,"name":"Курьер","items-name":"username","input-key":"couriers"},on:{"change":function($event){return _vm.$emit('set-input')}}})],1),_c('th',{staticClass:"orders_head",staticStyle:{"max-width":"130px"}},[_c('AutoComplete',{attrs:{"input":_vm.input,"items":_vm.picker,"name":"Сборщик","items-name":"username","input-key":"pickers"},on:{"change":function($event){return _vm.$emit('set-input')}}})],1),_c('th',{staticClass:"orders_head"})])]),_c('tbody',_vm._l((_vm.orders),function(item,index){return _c('tr',{key:item.id,staticClass:"orders_row"},[(
            _vm.$power.check('admin_order_edit') ||
            _vm.$power.check('admin_order_delete')
          )?_c('td',{staticClass:"orders_checkbox"},[_c('v-checkbox',{staticClass:"checkbox",attrs:{"hide-details":""},model:{value:(_vm.checks[index].state),callback:function ($$v) {_vm.$set(_vm.checks[index], "state", $$v)},expression:"checks[index].state"}})],1):_vm._e(),_c('td',{staticClass:"orders_item",staticStyle:{"width":"20px"}},[_vm._v(_vm._s(index + 1))]),_c('td',{staticClass:"orders_item",staticStyle:{"width":"50px"}},[_vm._v(" "+_vm._s(item.orderId || "0")+" ")]),_c('td',{staticClass:"orders_item",staticStyle:{"width":"50px"}},[_vm._v(" "+_vm._s(_vm.getNormalDate(item.date) || "Не указана")+" ")]),_c('td',{staticClass:"orders_item",staticStyle:{"width":"50px"}},[_vm._v(" "+_vm._s(_vm.getNormalDate(item.deliveryDate) || "Не указана")+" ")]),_c('td',{staticClass:"orders_item",staticStyle:{"width":"120px"}},[_vm._v(" "+_vm._s(item.client)+" ")]),_c('td',{staticClass:"orders_item",staticStyle:{"width":"120px"}},[_vm._v(" "+_vm._s(item.address)+" ")]),_c('td',{staticClass:"orders_item"},[_vm._v(_vm._s(item.price))]),_c('td',{staticClass:"orders_item",staticStyle:{"max-width":"130px"}},[_c('v-select',{staticClass:"orders_input",attrs:{"value":item.department,"items":_vm.departments,"item-text":"name","item-value":"id","dense":"","hide-details":""},on:{"change":function($event){return _vm.$emit('change-department', {
                order: item.orderId,
                department: $event,
              })}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-icon',{attrs:{"color":"#E93E7B"}},[_vm._v("mdi-menu-down")])]},proxy:true}],null,true)})],1),_c('td',{staticClass:"orders_item",staticStyle:{"max-width":"130px"}},[_c('v-select',{staticClass:"orders_input",attrs:{"value":item.paymentType,"items":_vm.payments,"item-text":"name","item-value":"id","dense":"","hide-details":""},on:{"change":function($event){return _vm.$emit('change-payment', {
                order: item.orderId,
                payment: $event,
              })}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-icon',{attrs:{"color":"#E93E7B"}},[_vm._v("mdi-menu-down")])]},proxy:true}],null,true)})],1),_c('td',{staticClass:"orders_item",staticStyle:{"max-width":"130px"}},[_c('v-select',{staticClass:"orders_input",attrs:{"background-color":_vm.getStatusColor(item.paymentStatus),"value":item.paymentStatus,"items":_vm.STATUS.payStatus,"item-text":"name","item-value":"id","dense":"","hide-details":""},on:{"change":function($event){return _vm.$emit('change_pay_status', {
                statusId: $event,
                orderId: item.orderId,
              })}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-icon',{attrs:{"color":"#E93E7B"}},[_vm._v("mdi-menu-down")])]},proxy:true}],null,true)})],1),_c('td',{staticClass:"orders_item",staticStyle:{"width":"120px"}},[_c('v-select',{staticClass:"orders_input",attrs:{"value":item.status,"items":_vm.statuses,"item-text":"name","item-value":"id","background-color":_vm.getStatusColor(item.status),"hide-details":"","dense":""},on:{"change":function($event){return _vm.emitChangeStatus({
                order: item.orderId,
                status: $event,
                paymentStatus: item.paymentType,
              })}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-icon',{attrs:{"color":"#E93E7B"}},[_vm._v("mdi-menu-down")])]},proxy:true}],null,true)})],1),_c('td',{staticClass:"orders_item",staticStyle:{"max-width":"130px"}},[_c('v-select',{staticClass:"orders_input",attrs:{"items":_vm.courier,"value":item.courier,"item-text":"username","item-value":"id","dense":"","hide-details":""},on:{"change":function($event){return _vm.emitChangeCourier({
                order: item.orderId,
                courier: $event,
              })}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-icon',{attrs:{"color":"#E93E7B"}},[_vm._v("mdi-menu-down")])]},proxy:true}],null,true)})],1),_c('td',{staticClass:"orders_item",staticStyle:{"max-width":"130px"}},[_c('v-select',{staticClass:"orders_input",attrs:{"items":_vm.picker,"item-text":"username","item-value":"id","dense":"","hide-details":""},on:{"change":function($event){return _vm.$emit('change-picker', { order: item.orderId, picker: $event })}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-icon',{attrs:{"color":"#E93E7B"}},[_vm._v("mdi-menu-down")])]},proxy:true}],null,true),model:{value:(item.picker),callback:function ($$v) {_vm.$set(item, "picker", $$v)},expression:"item.picker"}})],1),_c('td',{staticClass:"orders_item",staticStyle:{"width":"45px"}},[_c('v-btn',{attrs:{"color":"#F3993E","icon":"","x-large":""},on:{"click":function($event){_vm.orderId = item.orderId;
              _vm.show = true;}}},[_c('v-icon',[_vm._v("mdi-text-box")])],1)],1)])}),0)]),_c('v-card-actions',{staticClass:"orders_action"},[(_vm.orders.length < _vm.last)?_c('v-btn',{staticClass:"order-btns",attrs:{"color":"#51C8F0"},on:{"click":function($event){return _vm.$emit('load-more')}}},[_vm._v("Показать еще 15 заказов")]):_vm._e()],1),_c('PasswordDialog',{attrs:{"show":_vm.showPassDialog},on:{"close-modal":function($event){return _vm.closeCancelModal($event)}}}),_c('order-info',{attrs:{"show":_vm.show,"order-id":_vm.orderId},on:{"close_modal":function($event){_vm.show = false},"change_pay_status":function($event){return _vm.$emit('change_pay_status', $event)}}}),_c('bottom-bar',{attrs:{"id":_vm.id,"active":_vm.active,"edit":_vm.$power.check('admin_order_edit'),"delet":_vm.$power.check('admin_order_remove'),"disable":_vm.states.length > 1,"order":'order'},on:{"show":function($event){_vm.deleteDialog = true}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }